import React, { useContext, useState, useEffect } from 'react';
import './UploadManagement.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import Alert from '../Alert/Alert';
import { AuthContext } from '../Services/AuthContext'; 

function UploadManagement() {
    const navigate = useNavigate();
    const { project, loading } = useContext(AuthContext); 
    const [files, setFiles] = useState([]);
    const [alert, setAlert] = useState(null);
    const [showWarning, setShowWarning] = useState(false);

    const ReturnHome = () => {
        navigate("/gestion");
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 950) {
                setShowWarning(true);
            } else {
                setShowWarning(false);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (!loading && project) { 
            const folderId = project.folder_id; 

            fetch(`/Upload?folder_id=${folderId}`) 
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        setFiles(data.files);
                    } else {
                        setAlert({ message: data.message, type: 'error' });
                    }
                })
                .catch(error => {
                    console.error('Erreur lors de la récupération des fichiers:', error);
                    setAlert({ message: 'Erreur lors de la récupération des fichiers.', type: 'error' });
                });
        }
    }, [loading, project]);

    const handleDownload = (fileName) => {
        const fileUrl = `/Upload?fileName=${fileName}`;
    
        fetch(fileUrl)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Erreur lors de la récupération du fichier: ${response.statusText}`);
                }
                return response.blob();
            })
            .then(blob => {
                const blobUrl = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = fileName; 
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(blobUrl);
                setAlert({ message: `Le fichier ${fileName} a été téléchargé avec succès.`, type: 'success' });
            })
            .catch(error => {
                console.error('Erreur lors du téléchargement du fichier:', error);
                setAlert({ message: `Erreur lors du téléchargement du fichier: ${error.message}`, type: 'error' });
            });
    };      

    const handleDelete = (fileName) => {
        const folderId = project.folder_id; 

        fetch('/Upload', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ fileName: fileName, folder_id: folderId })
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
                setAlert({ message: `Le fichier ${fileName} a été supprimé avec succès.`, type: 'success' });
            } else {
                setAlert({ message: data.message, type: 'error' });
            }
        })
        .catch(error => {
            console.error('Erreur lors de la suppression du fichier:', error);
            setAlert({ message: 'Erreur lors de la suppression du fichier.', type: 'error' });
        });
    };

    return (
        <div className='background-upload-management'>
            {showWarning ? (
                <div className="no-screen">
                    <p>Votre appareil ou le format de l'écran n'est pas compatible avec cette application. Veuillez utiliser un appareil avec une résolution supérieure à 950px de largeur.</p>
                </div>
            ) : (
                <div className='wrapper-upload-management'>
                    <div className='container-back'>
                        <button onClick={ReturnHome}><FontAwesomeIcon icon={faArrowLeft} /></button>
                    </div>
                    <h1>Contenu du dossier</h1>
                    <div className='container-upload-management'>
                        {files.length > 0 ? (
                            files.map((file, index) => (
                                <div className='box-file' key={index}>
                                    <h2>{file.name}</h2>
                                    <button onClick={() => handleDownload(file.name)}>
                                        <FontAwesomeIcon icon={faDownload} />
                                    </button>
                                    <button onClick={() => handleDelete(file.name)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </div>
                            ))
                        ) : (
                            <div className='box-file'>
                                <h2>Pas de fichier disponible.</h2>
                            </div>
                        )}
                    </div>
                    {alert && <Alert message={alert.message} type={alert.type} onClose={() => setAlert(null)} />}
                </div>
            )}
        </div>
    );
}

export default UploadManagement;
