import React, { useState, useEffect, useCallback, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import './UserManagement.css';
import Alert from '../Alert/Alert';
import UserManagementDialog from './UserManagementDialog/UserManagementDialog';
import { AuthContext } from '../Services/AuthContext';

const UserManagement = () => {
    const { user: currentUser } = useContext(AuthContext); 
    const [users, setUsers] = useState([]); 
    const [loading, setLoading] = useState(true); 
    const [alert, setAlert] = useState({ message: '', type: '', visible: false }); 
    const [showDialog, setShowDialog] = useState(false);
    const [editUser, setEditUser] = useState(null); 
    const [showWarning, setShowWarning] = useState(false); 

    const fetchUsers = useCallback(async () => {
        try {
            const response = await fetch('/Users');
            const data = await response.json();
            if (data.success) {
                setUsers(data.users);
            } else {
                showAlert(data.message || 'Erreur lors de la récupération des utilisateurs', 'error');
            }
        } catch (error) {
            showAlert('Erreur lors de la connexion à l\'API : ' + error.message, 'error');
        } finally {
            setLoading(false);
        }
    }, []); 

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 950) {
                setShowWarning(true);
            } else {
                setShowWarning(false);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const showAlert = (message, type) => {
        setAlert({ message, type, visible: true });
    };

    const hideAlert = () => {
        setAlert({ ...alert, visible: false });
    };

    const deleteUser = async (userId) => {
        if (currentUser && currentUser.id === userId) {
            return;
        }

        try {
            const response = await fetch(`/Users`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `id=${userId}` 
            });
            const data = await response.json();
            if (data.success) {
                setUsers(users.filter(user => user.id !== userId)); 
                showAlert('Utilisateur supprimé avec succès', 'success');
            } else {
                showAlert(data.message || 'Erreur lors de la suppression de l\'utilisateur', 'error');
            }
        } catch (error) {
            showAlert('Erreur lors de la connexion à l\'API : ' + error.message, 'error');
        }
    };

    const openDialog = (user = null) => {
        setEditUser(user); 
        setShowDialog(true);
    };

    const handleUserCreated = () => {
        fetchUsers(); 
        showAlert('Utilisateur créé avec succès', 'success');
    };

    const handleUserUpdated = () => {
        fetchUsers(); 
        showAlert('Utilisateur mis à jour avec succès', 'success');
    };

    return (
        <div>
            {showWarning ? (
                <div className="no-screen">
                    <p>Votre appareil ou le format de l'écran n'est pas compatible avec cette application. Veuillez utiliser un appareil avec une résolution supérieure à 950px de largeur.</p>
                </div>
            ) : (
                <>
                    {alert.visible && (
                        <Alert message={alert.message} type={alert.type} onClose={hideAlert} />
                    )}
                    <div className='background-users-management'>
                        <div className='wrapper-users-management'>
                            <h1>Gestion des utilisateurs</h1>
                            {loading && <p>Chargement des utilisateurs...</p>}
                            <div className='container-users'>
                                {users.map(user => (
                                    <div className='users-box' key={user.id}>
                                        <div className='container-info-box'>
                                            <h2>{user.forname} {user.lastname}</h2>
                                            <h3>{user.grade}</h3>
                                            <h4>{user.role === 'admin' ? 'Administrateur' : 'Utilisateur'}</h4>
                                        </div>
                                        <div className='container-btn-box'>
                                            <button onClick={() => openDialog(user)}><FontAwesomeIcon icon={faPen} /></button>
                                            <button onClick={() => deleteUser(user.id)} disabled={currentUser && currentUser.id === user.id}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className='container-btn'>
                                <button onClick={() => openDialog()}>Ajouter un utilisateur</button>
                            </div>
                        </div>
                    </div>
                    <UserManagementDialog
                        visible={showDialog}
                        onClose={() => setShowDialog(false)}
                        onUserCreated={handleUserCreated}
                        onUserUpdated={handleUserUpdated}
                        editUser={editUser} 
                    />
                </>
            )}
        </div>
    );
};

export default UserManagement;
