import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import './Chat.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom'; 
import { AuthContext } from '../Services/AuthContext';

function Chat() {
    const navigate = useNavigate();
    const location = useLocation();  
    const { user, project } = useContext(AuthContext); 
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 950);
    const containerRef = useRef(null);
    const textareaRef = useRef(null);
    const abortControllerRef = useRef(null);
    const isScrolledToBottom = useRef(true);

    const folderId = location.state?.folder_id;

    const userId = user?.id || project?.code;
    const userRole = user ? 'staff' : 'client';

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 950);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const fetchMessages = useCallback(() => {
        if (!folderId || !userId) {
            return;
        }

        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        const newAbortController = new AbortController();
        abortControllerRef.current = newAbortController;

        fetch(`/Chat?folder_id=${folderId}&user_id=${userId}&role=${userRole}`, {
            signal: newAbortController.signal,
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setMessages(data.messages); 
                }
            })
            .catch(error => {
                if (error.name !== 'AbortError') {
                    console.error('Erreur lors de la récupération des messages:', error);
                }
            });
    }, [folderId, userId, userRole]);

    useEffect(() => {
        if (!folderId || !userId) {
            return;
        }
        fetchMessages();
        const interval = setInterval(fetchMessages, 500);

        return () => {
            clearInterval(interval);
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, [folderId, userId, fetchMessages]);

    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;

        if (isScrolledToBottom.current) {
            container.scrollTop = container.scrollHeight;
        }

        const handleScroll = () => {
            const isAtBottom = container.scrollHeight - container.scrollTop === container.clientHeight;
            isScrolledToBottom.current = isAtBottom;
        };

        container.addEventListener('scroll', handleScroll);

        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, [messages]);

    const handleSendMessage = () => {
        if (message.trim() === '') return;
    
        const senderType = user && user.role ? 'staff' : 'client';
        const body = {
            folder_id: folderId, 
            sender_type: senderType,
            message: message,
            user_id: senderType === 'staff' ? user.id : project.code
        };
    
        fetch('/Chat', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Erreur réseau');
            }
            return response.json(); 
        })
        .then(jsonData => {
            if (jsonData.success) {
                setMessage('');
                fetchMessages(); 
            } else {
                console.error('Erreur serveur:', jsonData.message);
            }
        })
        .catch(error => {
            setMessage(''); 
            console.error('Erreur lors de l\'envoi du message:', error);
        });
    };    

    const handleKeyDown = (e) => {
        if (!isMobileView && e.key === 'Enter' && !e.shiftKey) {
            handleSendMessage();
            e.preventDefault();
        }
    };

    const adjustTextareaHeight = () => {
        if (!isMobileView && textareaRef.current) {
            textareaRef.current.style.height = '45px';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };

    const handleInputChange = (e) => {
        setMessage(e.target.value);
        adjustTextareaHeight();
    };

    const formatTime = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleTimeString('fr-FR', {
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', {
            weekday: 'short',
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });
    };

    return (
        <div className='background-chat'>
            <div className='wrapper-chat'>
                <div className='container-header'>
                    <button className='return' onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                    <h1>Discussion du projet</h1>
                </div>
                <div className='container-input-chat'>
                    <div className='container-chat' ref={containerRef}>
                        {messages.map((msg, index) => {
                            const currentDate = new Date(msg.created_at);
                            const previousDate = index > 0 ? new Date(messages[index - 1].created_at) : null;
                            const showDate = index === 0 || currentDate.toDateString() !== previousDate.toDateString();

                            return (
                                <React.Fragment key={index}>
                                    {showDate && (
                                        <div className='container-date'>
                                            <p>{formatDate(msg.created_at)}</p>
                                        </div>
                                    )}
                                    <div className={msg.sender_type === 'client' ? 'container-talk-myself' : 'container-talk-staff'}>
                                        <div className='chat-bubble' style={{ backgroundColor: msg.sender_type === 'client' ? 'rgb(39, 152, 218)' : '#3f3f3f' }}>
                                            <h2>{msg.sender_type === 'client' ? 'Client' : msg.grade || 'Staff'}</h2>
                                            <p dangerouslySetInnerHTML={{ __html: msg.message }}></p>
                                            <div className='container-hours'>
                                                <p>{formatTime(msg.created_at)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>
                <div className='container-input'>
                    <textarea 
                        ref={textareaRef}
                        placeholder="Écrivez votre message..." 
                        aria-label="Écrire un message"
                        className='chat-input'
                        value={message}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                    />
                    <button onClick={handleSendMessage}>
                        <FontAwesomeIcon icon={faPaperPlane} />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Chat;
