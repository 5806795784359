import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Alert from '../../Alert/Alert'; 
import './UserManagementDialog.css';

const UserManagementDialog = ({ visible, onClose, onUserCreated, onUserUpdated, editUser }) => {
    const [prenom, setPrenom] = useState('');
    const [nom, setNom] = useState('');
    const [email, setEmail] = useState('');
    const [grade, setGrade] = useState('');
    const [role, setRole] = useState('user');
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ message: '', type: '', visible: false });
    const [showWarning, setShowWarning] = useState(false); 

    useEffect(() => {
        if (editUser) {
            setPrenom(editUser.forname);
            setNom(editUser.lastname);
            setEmail(editUser.email);
            setGrade(editUser.grade);
            setRole(editUser.role);
        } else {
            resetForm();
        }
    }, [editUser]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 950) {
                setShowWarning(true);
            } else {
                setShowWarning(false);
            }
        };

        handleResize(); 

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const resetForm = () => {
        setPrenom('');
        setNom('');
        setEmail('');
        setGrade('');
        setRole('user');
    };

    const showAlert = (message, type) => {
        setAlert({ message, type, visible: true });
    };

    const hideAlert = () => {
        setAlert({ ...alert, visible: false });
    };

    const handleCreateUser = async () => {
        if (!prenom || !nom || !email || !grade || !role) {
            showAlert('Paramètres manquants pour créer un utilisateur', 'error');
            return;
        }

        setLoading(true);

        const userData = {
            forname: prenom,
            lastname: nom,
            email: email,
            grade: grade,
            role: role,
            username: `${prenom}.${nom}`.toLowerCase(),
        };

        try {
            const response = await fetch('/Users', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
            });

            const data = await response.json();
            if (data.success) {
                resetForm();
                onClose();
                onUserCreated(); 
            } else {
                showAlert(data.message || 'Erreur lors de la création de l\'utilisateur', 'error');
            }
        } catch (error) {
            showAlert('Erreur lors de la connexion à l\'API: ' + error.message, 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleUpdateUser = async () => {
        if (!prenom || !nom || !email || !grade || !role) {
            showAlert('Paramètres manquants pour modifier l\'utilisateur', 'error');
            return;
        }
    
        const userData = {
            id: editUser.id,  
            forname: prenom,
            lastname: nom,
            email: email,
            grade: grade,
            role: role,
        };
    
        try {
            const response = await fetch(`/Users?id=${editUser.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
            });
    
            const data = await response.json();
    
            if (data.success) {
                resetForm();
                onClose();
                onUserUpdated(); 
            } else {
                showAlert(data.message || 'Erreur lors de la modification de l\'utilisateur', 'error');
            }
        } catch (error) {
            showAlert('Erreur lors de la connexion à l\'API: ' + error.message, 'error');
        }
    };      

    const handleResendCode = async () => {
        if (!editUser || !email) {
            showAlert('Impossible de renvoyer le code : utilisateur ou email manquant', 'error');
            return;
        }
    
        try {
            const response = await fetch(`/Users`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });            
    
            const data = await response.json();
            if (data.success) {
                showAlert('Code de vérification renvoyé avec succès', 'success');
            } else {
                showAlert(data.message || 'Erreur lors de l\'envoi du code de vérification', 'error');
            }
        } catch (error) {
            showAlert('Erreur lors de la connexion à l\'API: ' + error.message, 'error');
        }
    };
    
    const handleCancel = () => {
        resetForm();
        onClose();
    };

    return (
        <AnimatePresence>
            {showWarning ? (
                <div className="no-screen">
                    <p>Votre appareil ou le format de l'écran n'est pas compatible avec cette application. Veuillez utiliser un appareil avec une résolution supérieure à 950px de largeur.</p>
                </div>
            ) : (
                visible && (
                    <motion.div
                        className='background-users-management-dialog'
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <motion.div
                            className='wrapper-users-management-dialog'
                            initial={{ y: -500, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -500, opacity: 0 }}
                            transition={{ duration: 0.3 }}
                        >
                            {alert.visible && (
                                <Alert message={alert.message} type={alert.type} onClose={hideAlert} />
                            )}
                            <h1>{editUser ? 'Modification d\'un utilisateur' : 'Création d\'un utilisateur'}</h1>
                            <div className='container-name'>
                                <InputBox label="Prénom" type="text" value={prenom} onChange={e => setPrenom(e.target.value)} />
                                <InputBox label="Nom" type="text" value={nom} onChange={e => setNom(e.target.value)} />
                            </div>
                            <InputBox label="E-mail" type="text" value={email} onChange={e => setEmail(e.target.value)} />
                            <div className='container-info'>
                                <InputBox label="Grade" type="text" value={grade} onChange={e => setGrade(e.target.value)} />
                                <div className='input-box'>
                                    <label htmlFor="permission">Permission</label>
                                    <select id="permission" value={role} onChange={e => setRole(e.target.value)}>
                                        <option value="user">Utilisateur</option>
                                        <option value="admin">Administrateur</option>
                                    </select>
                                </div>
                            </div>
                            <div className='container-btn'>
                                <button onClick={handleCancel}>Annuler</button>
                                {editUser && (
                                    <button onClick={handleResendCode}>Renvoyer le code</button>
                                )}
                                {editUser ? (
                                    <button onClick={handleUpdateUser}>Mettre à jour l'utilisateur</button>
                                ) : (
                                    <button onClick={handleCreateUser} disabled={loading}>
                                        {loading ? 'Création en cours...' : 'Créer l\'utilisateur'}
                                    </button>
                                )}
                            </div>
                        </motion.div>
                    </motion.div>
                )
            )}
        </AnimatePresence>
    );
};

const InputBox = ({ label, type, value, onChange }) => (
    <div className='input-box'>
        <label>{label}</label>
        <input type={type} value={value} onChange={onChange} />
    </div>
);

export default UserManagementDialog;
