import React, { useState, useEffect } from 'react';
import './UploadClient.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faXmark } from '@fortawesome/free-solid-svg-icons';
import Alert from '../Alert/Alert'; 

function UploadClient() {
    const navigate = useNavigate();
    const location = useLocation();
    const [files, setFiles] = useState([]);
    const [alert, setAlert] = useState(null);
    const [loading, setLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0); 

    const folderId = location.state?.folder_id;

    useEffect(() => {
        if (!folderId) {
            setAlert({ message: 'ID du dossier non fourni.', type: 'error' });
        }
    }, [folderId]);

    const ReturnHome = () => {
        navigate("/accueil");
    };

    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files); 
        const validTypes = [
            'image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/bmp', 
            'image/tiff', 'image/svg+xml', 'image/x-icon', 'image/heif', 'image/heic',
            'video/mp4', 'video/webm', 'video/ogg', 'video/x-msvideo', 
            'video/quicktime', 'video/x-ms-wmv', 'video/x-matroska', 
            'video/x-flv', 'video/3gpp', 'video/x-m4v',
            'application/pdf', 'application/msword', 
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'text/plain'
        ];
        
        const validFiles = selectedFiles.filter(file => {
            if (!validTypes.includes(file.type)) {
                setAlert({ message: `Type de fichier non supporté ${file.type}`, type: 'error' });
                return false;
            }
            return true;
        });
        
        setFiles(prevFiles => [...prevFiles, ...validFiles]);
    };    

    const handleFileDelete = (fileToDelete) => {
        setFiles(prevFiles => prevFiles.filter(file => file !== fileToDelete));
    };

    const handleUpload = () => {
        if (!files.length) {
            return;
        }

        const formData = new FormData();
        files.forEach(file => formData.append('files[]', file));
        formData.append('folder_id', folderId);

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/Upload', true);

        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
                const percentComplete = (event.loaded / event.total) * 100;
                setUploadProgress(percentComplete);
            }
        };

        xhr.onload = () => {
            if (xhr.status === 200) {
                const response = JSON.parse(xhr.responseText);
                if (response.success) {
                    setAlert({ message: 'Fichiers téléchargés avec succès', type: 'success' });
                    setFiles([]); 
                } else {
                    setAlert({ message: response.message, type: 'error' });
                }
            } else {
                setAlert({ message: 'Erreur lors du téléchargement. Veuillez réessayer.', type: 'error' });
            }
            setLoading(false); 
            setUploadProgress(0); 
        };

        xhr.onerror = () => {
            setAlert({ message: 'Erreur lors du téléchargement. Veuillez réessayer.', type: 'error' });
            setLoading(false);
            setUploadProgress(0);
        };

        setLoading(true);
        xhr.send(formData);
    };

    if (!folderId) {
        return (
            <div className='background-upload'>
                <div className='wrapper-upload'>
                    <div className='container-back'>
                        <button onClick={ReturnHome}><FontAwesomeIcon icon={faArrowLeft} /></button>
                    </div>
                    <Alert message="ID du dossier non fourni." type="error" onClose={() => navigate("/accueil")} />
                </div>
            </div>
        );
    }

    return (
        <div className='background-upload'>
            <div className='wrapper-upload'>
                <div className='container-back'>
                    <button onClick={ReturnHome}><FontAwesomeIcon icon={faArrowLeft} /></button>
                </div>
                <h1>Fournir du contenu</h1>
                <div className='container-upload' onClick={() => document.getElementById('file-upload').click()}>
                    <p>Déposer des fichiers ici</p>
                    <input
                        type="file"
                        onChange={handleFileChange}
                        multiple
                        style={{ display: 'none' }}
                        id="file-upload"
                    />
                </div>
                {files.length === 0 && (
                    <h2>Merci de renommer le fichier selon la page où il devrait se trouver.</h2>
                )}
                {files.length > 0 && (
                    <div className='container-file'>
                        {files.map((file, index) => (
                            <div className='file' key={index}>
                                <p>
                                    {file.name.length > 20 ? `${file.name.slice(0, 20)}...` : file.name}
                                </p>
                                <FontAwesomeIcon className='delete' icon={faXmark} onClick={() => handleFileDelete(file)} />
                            </div>
                        ))}
                    </div>
                )}

                <div className='container-btn'>
                    {loading ? (
                        <div className="upload-progress">
                            <p className="progress-text">{Math.round(uploadProgress)}%</p>
                            <div className="spinner-upload"></div>
                        </div>
                    ) : (
                        <button disabled={files.length === 0} onClick={handleUpload}>Envoyer</button>
                    )}
                </div>

                {alert && <Alert message={alert.message} type={alert.type} onClose={() => setAlert(null)} />}
            </div>
        </div>
    );
}

export default UploadClient;
