import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from '../Alert/Alert';
import { AuthContext } from '../Services/AuthContext';
import Icon_connection from '../Images/connection.svg';
import Background_connection from '../Images/background_connection.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import Logo from '../Images/favicon.svg';
import './Connection.css';

function Connection() {
    const [accessCode, setAccessCode] = useState('');
    const [alert, setAlert] = useState(null);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth); 
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false); 
    const { login } = useContext(AuthContext);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSubmit = () => {
        if (!accessCode) {
            setAlert({ message: 'Veuillez entrer un code d\'accès.', type: 'error' });
            return;  
        }
    
        setLoading(true);  
    
        setTimeout(() => {
            fetch('/Connection', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ code: accessCode }),
            })
            .then(response => response.json())
            .then(data => {
                setLoading(false);
            
                if (data.success) {
                    localStorage.setItem('token', data.token);
                    login(data.token, data.project);
            
                    if (data.type === 'user') {
                        if (screenWidth < 950) {
                            setAlert({
                                message: 'Écran non compatible. Utilisez une largeur de minimum 950px.',
                                type: 'error',
                            });
                        } else {
                            navigate('/gestion');
                        }
                    } else if (data.type === 'website' || data.type === 'digital') {
                        navigate('/accueil');
                    }
                } else {
                    setAlert({ message: data.message, type: 'error' });
                }
            
            })
            .catch(error => {
                setLoading(false);  
                setAlert({ message: 'Une erreur est survenue. Veuillez réessayer.', type: 'error' });
            });
        }, 3000); 
    };
    
    return (
        <div className='background-connection'>
            <div className='wrapper-connection'>
                <div className='container-left'>
                    <h1>WebProgress</h1>
                    <h2>Créé par LogixMedia</h2>
                    <div className='input-box'>
                        <span><FontAwesomeIcon icon={faLock} /></span>
                        <input
                            type="text"
                            id="access-code"
                            value={accessCode}
                            placeholder="Code d'accès"
                            onChange={(e) => {
                                const inputValue = e.target.value.toUpperCase();

                                const filteredValue = inputValue.replace(/[^A-Z0-9]/g, '');

                                if (filteredValue.length <= 12) {
                                    setAccessCode(filteredValue);
                                }
                            }}
                        />
                    </div>
                    <div className='container-btn'>
                        {loading ? (
                            <div className="spinner-connection"></div> 
                        ) : (
                            <button onClick={handleSubmit}>Continuer</button> 
                        )}
                    </div>
                </div>
                <div className='container-right'>
                    <img src={Background_connection} alt="Arrière-plan de connexion" />
                </div>
                
                <img className='icon-connexion' src={Icon_connection} alt="Icône de connexion" />
                
                <img className='logo' src={Logo} alt="Logo WebProgress" />
            </div>
            {alert && <Alert message={alert.message} type={alert.type} onClose={() => setAlert(null)} />}
        </div>
    );
}

export default Connection;
