import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './HomeClient.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faFolderOpen, faShare, faComment } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../Services/AuthContext';

function HomeClient() {
    const { project, setProject } = useContext(AuthContext);
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 500);
    const [hoveredOption, setHoveredOption] = useState(null);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 500);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (!project) {
            const storedProject = localStorage.getItem('activeProject');
            if (storedProject) {
                const parsedProject = JSON.parse(storedProject);
                setProject(parsedProject);  
            }
        }
    }, [project, setProject]);

    if (!project) {
        return <div>Chargement...</div>;
    }

    const handleAccessFolder = () => {
        if (project && project.folder_id) {
            navigate('/dossier', { state: { folder_id: project.folder_id } });
        }
    };

    const handleAccessUpload = () => {
        if (project && project.folder_id) {
            navigate('/contenu', { state: { folder_id: project.folder_id } });
        }
    };

    const handleAccessChat = () => {
        if (project && project.folder_id) {
            navigate(`/discussion/${project.id}`, { state: { folder_id: project.folder_id } });
        }
    };

    const handleMouseEnter = (option) => {
        setHoveredOption(option);
    };

    const handleMouseLeave = () => {
        setHoveredOption(null);
    };

    return (
        <div className='wrapper-home'>
            <h1>{project.name || "Nom du projet non disponible"}</h1>
            <h2>N° {project.folder_id}</h2>
            <div className='container-home'>
                <div 
                    className='home-option' 
                    onClick={handleAccessFolder} 
                    onMouseEnter={() => handleMouseEnter('folder')}
                    onMouseLeave={handleMouseLeave}
                >
                    <div className='container-icon'>
                        <FontAwesomeIcon className='icon' icon={faFolderOpen} />
                    </div>
                    <p>Accéder au dossier</p>
                    <button>
                        <FontAwesomeIcon 
                            icon={faChevronRight} 
                            style={{
                                transform: hoveredOption === 'folder' ? 'scale(1.5)' : 'scale(1)', 
                                transition: 'transform 0.3s ease'
                            }} 
                        />
                    </button>
                </div>
                <div 
                    className='home-option' 
                    onClick={handleAccessUpload} 
                    onMouseEnter={() => handleMouseEnter('upload')}
                    onMouseLeave={handleMouseLeave}
                >
                    <div className='container-icon'>
                        <FontAwesomeIcon className='icon' icon={faShare} />
                    </div>
                    <p>{isMobile ? 'Fournir du contenu' : 'Fournir du contenu (PDF, Image ...)'}</p>
                    <button>
                        <FontAwesomeIcon 
                            icon={faChevronRight} 
                            style={{
                                transform: hoveredOption === 'upload' ? 'scale(1.5)' : 'scale(1)', 
                                transition: 'transform 0.3s ease'
                            }} 
                        />
                    </button>
                </div>
                <div 
                    className='home-option' 
                    onClick={handleAccessChat} 
                    onMouseEnter={() => handleMouseEnter('chat')}
                    onMouseLeave={handleMouseLeave}
                >
                    <div className='container-icon'>
                        <FontAwesomeIcon className='icon' icon={faComment} />
                    </div>
                    <p>Envoyer un message</p>
                    <button>
                        <FontAwesomeIcon 
                            icon={faChevronRight} 
                            style={{
                                transform: hoveredOption === 'chat' ? 'scale(1.5)' : 'scale(1)', 
                                transition: 'transform 0.3s ease'
                            }} 
                        />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default HomeClient;
