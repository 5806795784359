import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import './PreviewCreate.css';
import Alert from '../Alert/Alert';

const PreviewCreate = () => {
    const navigate = useNavigate();
    const [projectType, setProjectType] = useState(''); 
    const [projectName, setProjectName] = useState('');
    const [theme, setTheme] = useState('');
    const [colors, setColors] = useState('');
    const [audience, setAudience] = useState('');
    const [objectives, setObjectives] = useState('');
    const [keywords, setKeywords] = useState('');
    const [competitors, setCompetitors] = useState('');
    const [notes, setNotes] = useState('');
    const [amount, setAmount] = useState('');
    const [email, setEmail] = useState('');
    const [previewUrl, setPreviewUrl] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [progress] = useState(5); 
    const [selectedUserId, setSelectedUserId] = useState('');
    const [assignedUsers, setAssignedUsers] = useState([]); 
    const [users, setUsers] = useState([]);
    const [alert, setAlert] = useState({ message: '', type: '', show: false });
    const [showWarning, setShowWarning] = useState(false); 
    const [socialMedia, setSocialMedia] = useState({
        facebook: false,
        facebookLink: '',
        instagram: false,
        instagramLink: '',
        youtube: false,
        youtubeLink: '',
        linkedin: false,
        linkedinLink: '',
        tiktok: false,
        tiktokLink: '',
        snapchat: false,
        snapchatLink: ''  
    });    
    const [presence, SetPresence] = useState('');

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 950) {
                setShowWarning(true);
            } else {
                setShowWarning(false);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetch('/Users?action=get_users');
                const data = await response.json();
                if (data.success) {
                    setUsers(data.users);
                } else {
                    console.error('Error fetching users:', data.message);
                }
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, []);

    const handleAddUser = (userId) => {
        const user = users.find(u => u.id === parseInt(userId));
        if (user && !assignedUsers.some(u => u.id === user.id)) { 
            setAssignedUsers([...assignedUsers, user]); 
        }
        setSelectedUserId(''); 
    };    

    const handleRemoveUser = (userId) => {
        setAssignedUsers(assignedUsers.filter(user => user.id !== userId)); 
    };

    const renderAssignedUsers = () => {
        return assignedUsers.map(user => (
            <div key={user.id} className="assigned-box">
                <p>{user.forname} {user.lastname}</p>
                <FontAwesomeIcon 
                    className="delete-assigned-box" 
                    icon={faXmark} 
                    onClick={() => handleRemoveUser(user.id)} 
                />
            </div>
        ));
    };

    const validateForm = () => {
        if (!projectName) {
            setAlert({ message: 'Le nom du projet est requis.', type: 'error', show: true });
            return false;
        }
        if (assignedUsers.length === 0) { 
            setAlert({ message: 'Veuillez sélectionner au moins un utilisateur pour assigner ce projet.', type: 'error', show: true });
            return false;
        }
        if (!amount) {  
            setAlert({ message: 'Le montant est requis.', type: 'error', show: true });
            return false;
        }
        if (!startDate) {  
            setAlert({ message: 'La date de début est requise.', type: 'error', show: true });
            return false;
        }
        if (!endDate) {  
            setAlert({ message: 'La date de fin est requise.', type: 'error', show: true });
            return false;
        }
        return true;
    };

    const generateFolderId = async () => {
        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleDateString('fr-FR').replace(/\//g, '');
    
        try {
            const response = await fetch(`/Project?action=count&date=${formattedDate}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
            if (!response.ok) {
                const errorText = await response.text();
                console.error('Erreur HTTP : ', errorText);
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();
    
            if (data.success && typeof data.projectCount === 'number') {
                const folderId = `${formattedDate}-${data.projectCount + 1}`;
                return folderId;
            } else {
                throw new Error('Erreur lors du comptage des projets');
            }
        } catch (error) {
            console.error('Erreur lors de la génération du folder_id :', error);
            return null;
        }
    };

    const handleCreateWebsite = async () => {
        if (!validateForm()) return;

        const folderId = await generateFolderId();
        if (!folderId) {
            setAlert({ message: 'Erreur lors de la génération du folder_id', type: 'error', show: true });
            return;
        }

        const previewUrlToSave = previewUrl || 'vide';
        const formattedStartDate = convertDateToDBFormat(startDate);
        const formattedEndDate = convertDateToDBFormat(endDate);

        const assignedUserIds = assignedUsers.map(user => user.id);

        const newWebsite = {
            type: 'website',
            name: projectName,
            assigned: assignedUserIds,  
            folder_id: folderId,  
            site_theme: theme,
            site_colors: colors,
            target_audience: audience,
            objectives: objectives,
            keywords: keywords,
            competitors: competitors,
            additional_notes: notes,
            amount: amount,
            email: email,
            preview_url: previewUrlToSave,
            start: formattedStartDate,
            end: formattedEndDate,
            progress: progress,
            action: 'create_project'  
        };

        try {
            const response = await fetch(`/Project`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newWebsite),  
            });

            const data = await response.json();
            if (data.success) {
                setAlert({ message: 'Projet MyWebsite avec succès', type: 'success', show: true });
                navigate("/gestion", { state: { successMessage: 'Projet MyWebsite avec succès' } });
            } else {
                setAlert({ message: data.message, type: 'error', show: true });
            }
        } catch (error) {
            setAlert({ message: 'Erreur lors de la création du site Web', type: 'error', show: true });
        }
    };    

    const handleCreateDigitalCampaign = async () => {
        if (!validateForm()) return;
    
        const folderId = await generateFolderId();
        if (!folderId) {
            setAlert({ message: 'Erreur lors de la génération du folder_id', type: 'error', show: true });
            return;
        }
    
        const formattedStartDate = convertDateToDBFormat(startDate);
        const formattedEndDate = convertDateToDBFormat(endDate);
    
        const assignedUserIds = assignedUsers.map(user => user.id);
    
        const newCampaign = {
            type: 'digitalcampaigns',
            name: projectName,
            folder_id: folderId,
            assigned: assignedUserIds,
            facebook: socialMedia.facebook ? 1 : 0,
            instagram: socialMedia.instagram ? 1 : 0,
            youtube: socialMedia.youtube ? 1 : 0,
            linkedin: socialMedia.linkedin ? 1 : 0,
            tiktok: socialMedia.tiktok ? 1 : 0,
            snapchat: socialMedia.snapchat ? 1 : 0,
            social_facebook: socialMedia.facebook ? socialMedia.facebookLink : null,
            social_instagram: socialMedia.instagram ? socialMedia.instagramLink : null,
            social_youtube: socialMedia.youtube ? socialMedia.youtubeLink : null,
            social_linkedin: socialMedia.linkedin ? socialMedia.linkedinLink : null,
            social_tiktok: socialMedia.tiktok ? socialMedia.tiktokLink : null,
            social_snapchat: socialMedia.snapchat ? socialMedia.snapchatLink : null,
            objectives: objectives,
            presence: presence,
            email: email,
            amount: amount,
            start: formattedStartDate,
            end: formattedEndDate,
            action: 'create_project'
        };

        try {
            const response = await fetch(`/Project`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newCampaign),
            });
    
            if (!response.ok) {
                throw new Error('Failed to create digital campaign');
            }
    
            const data = await response.json();
            if (data.success) {
                setAlert({ message: 'Projet DigitalCampaigns avec succès', type: 'success', show: true });
                navigate("/gestion", { state: { successMessage: 'Projet DigitalCampaigns avec succès' } });
            } else {
                setAlert({ message: data.message, type: 'error', show: true });
            }
        } catch (error) {
            setAlert({ message: 'Erreur lors de la création de la campagne digitale', type: 'error', show: true });
        }
    };            

    const convertDateToDBFormat = (dateStr) => {
        const parts = dateStr.split('.');
        if (parts.length === 3) {
            const [day, month, year] = parts;
            return `${year}-${month}-${day}`; 
        }
        return ''; 
    };

    const handleCheckboxChange = (e) => {
        setSocialMedia({
            ...socialMedia,
            [e.target.name]: e.target.checked,
        });
    };

    const renderFormForProjectType = () => {
        switch (projectType) {
            case 'MyWebsite':
                return (
                    <>
                        <div className='input-box'>
                            <label htmlFor="project-name">Nom du projet</label>
                            <input 
                                type="text" 
                                id="project-name" 
                                name="project-name" 
                                placeholder="Entrez le nom du projet"
                                value={projectName} 
                                onChange={(e) => setProjectName(e.target.value)} 
                                maxLength={45} 
                            />
                        </div>
                        <div className='input-box'>
                            <label htmlFor="assigned-user">Assigner à un utilisateur</label>
                            <select 
                                id="assigned-user" 
                                name="assigned-user" 
                                value={selectedUserId} 
                                onChange={(e) => handleAddUser(e.target.value)} 
                            >
                                <option value="">Sélectionner un utilisateur</option>
                                {users.map((user) => (
                                    <option key={user.id} value={user.id}>
                                        {user.forname} {user.lastname}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='container-assigned'>
                            {renderAssignedUsers()}
                        </div>
                        <div className='input-box'>
                            <label htmlFor="site-theme">Que sera le thème du site ?</label>
                            <textarea 
                                id="site-theme" 
                                name="site-theme" 
                                placeholder="Entrez le thème du site"
                                value={theme} 
                                onChange={(e) => setTheme(e.target.value)}
                            ></textarea>
                        </div>
                        <div className='input-box'>
                            <label htmlFor="site-colors">Les couleurs principales à utiliser ?</label>
                            <textarea 
                                id="site-colors" 
                                name="site-colors" 
                                placeholder="Entrez les couleurs principales"
                                value={colors} 
                                onChange={(e) => setColors(e.target.value)}
                            ></textarea>
                        </div>
                        <div className='input-box'>
                            <label htmlFor="target-audience">Qui est votre public cible ?</label>
                            <textarea 
                                id="target-audience" 
                                name="target-audience" 
                                placeholder="Entrez le public cible"
                                value={audience} 
                                onChange={(e) => setAudience(e.target.value)}
                            ></textarea>
                        </div>
                        <div className='input-box'>
                            <label htmlFor="objectives">Quels sont vos principaux objectifs en ligne ?</label>
                            <textarea 
                                id="objectives" 
                                name="objectives" 
                                placeholder="Entrez vos objectifs"
                                value={objectives} 
                                onChange={(e) => setObjectives(e.target.value)}
                            ></textarea>
                        </div>
                        <div className='input-box'>
                            <label htmlFor="keywords">Quels sont les 3 à 5 mots-clés qui représentent votre entreprise ?</label>
                            <textarea 
                                id="keywords" 
                                name="keywords" 
                                placeholder="Entrez les mots-clés"
                                value={keywords} 
                                onChange={(e) => setKeywords(e.target.value)}
                            ></textarea>
                        </div>
                        <div className='input-box'>
                            <label htmlFor="competitors">Connaissez-vous vos principaux concurrents en ligne ?</label>
                            <textarea 
                                id="competitors" 
                                name="competitors" 
                                placeholder="Entrez vos concurrents"
                                value={competitors} 
                                onChange={(e) => setCompetitors(e.target.value)}
                            ></textarea>
                        </div>
                        <div className='input-box'>
                            <label htmlFor="additional-notes">Notes supplémentaires</label>
                            <textarea 
                                id="additional-notes" 
                                name="additional-notes" 
                                placeholder="Entrez vos objectifs"
                                value={notes} 
                                onChange={(e) => setNotes(e.target.value)}
                            ></textarea>
                        </div>
                        <div className='input-box'>
                            <label htmlFor="amount">Montant total</label>
                            <input 
                                type="text" 
                                id="amount" 
                                name="amount" 
                                placeholder="Entrez le montant total"
                                value={amount} 
                                onChange={(e) => setAmount(e.target.value)} 
                            />
                        </div>
                        <div className='input-box'>
                            <label htmlFor="email">E-mail du client</label>
                            <input 
                                type="email" 
                                id="email" 
                                name="email" 
                                placeholder="Entrez l'e-mail"
                                value={email} 
                                onChange={(e) => setEmail(e.target.value)} 
                            />
                        </div>
                        <div className='input-box'>
                            <label htmlFor="site-preview">Aperçu du site</label>
                            <input 
                                type="text" 
                                id="site-preview" 
                                name="site-preview" 
                                placeholder="Entrez l'URL de l'aperçu du site"
                                value={previewUrl} 
                                onChange={(e) => setPreviewUrl(e.target.value)} 
                            />
                        </div>
                        <div className='container-date'>
                            <div className='input-box'>
                                <label htmlFor="start-date">Date d'ouverture</label>
                                <input 
                                    type="text" 
                                    id="start-date" 
                                    name="start-date" 
                                    placeholder="DD.MM.YYYY"
                                    value={startDate} 
                                    onChange={(e) => setStartDate(e.target.value)} 
                                />
                            </div>
                            <div className='input-box'>
                                <label htmlFor="end-date">Date de finalisation</label>
                                <input 
                                    type="text" 
                                    id="end-date" 
                                    name="end-date" 
                                    placeholder="DD.MM.YYYY"
                                    value={endDate} 
                                    onChange={(e) => setEndDate(e.target.value)} 
                                />
                            </div>
                        </div>
                    </>
                );
            case 'DigitalCampaigns':
                return (
                    <>
                        <div className='input-box'>
                            <label htmlFor="project-name">Nom du projet</label>
                            <input 
                                type="text" 
                                id="project-name" 
                                name="project-name" 
                                placeholder="Entrez le nom du projet"
                                value={projectName} 
                                onChange={(e) => setProjectName(e.target.value)} 
                                maxLength={45} 
                            />
                        </div>
                        <div className='input-box'>
                            <label htmlFor="assigned-user">Assigner à un utilisateur</label>
                            <select 
                                id="assigned-user" 
                                name="assigned-user" 
                                value={selectedUserId} 
                                onChange={(e) => handleAddUser(e.target.value)} 
                            >
                                <option value="">Sélectionner un utilisateur</option>
                                {users.map((user) => (
                                    <option key={user.id} value={user.id}>
                                        {user.forname} {user.lastname}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='container-assigned'>
                            {renderAssignedUsers()}
                        </div>
                        <div className='input-box'>
                            <label>Sur quels réseaux sociaux êtes-vous actuellement actif ?</label>
                            <div className='checkbox-group'>
                                {['facebook', 'instagram', 'youtube', 'linkedin', 'tiktok', 'snapchat'].map((social) => (
                                    <div key={social} className='social-group'>
                                        <div className='container-social'>
                                            <input
                                                type="checkbox"
                                                id={social}
                                                name={social}
                                                checked={socialMedia[social]}
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor={social}>{social.charAt(0).toUpperCase() + social.slice(1)}</label>
                                        </div>
                                        {socialMedia[social] && (
                                            <div className="social-input">
                                                <input
                                                    type="url"
                                                    id={`${social}-link`}
                                                    name={`${social}-link`}
                                                    placeholder={`Entrez le lien de votre profil ${social}`}
                                                    value={socialMedia[`${social}Link`]}
                                                    onChange={(e) => setSocialMedia({ ...socialMedia, [`${social}Link`]: e.target.value })}
                                                />
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className='input-box'>
                            <label htmlFor="objectives">Quels sont vos principaux objectifs sur les réseaux sociaux ?</label>
                            <textarea
                                id="objectives"
                                name="objectives"
                                value={objectives}  
                                onChange={(e) => setObjectives(e.target.value)} 
                                placeholder="Décrivez votre présence actuelle ou vos objectifs"
                            ></textarea>
                        </div>

                        <div className='input-box'>
                            <label>Voulez-vous accroître votre présence sur les réseaux sociaux ?</label>
                            <textarea
                                id="presence"
                                name="presence"
                                placeholder="Décrivez votre présence actuelle ou vos objectifs"
                                value={presence}  
                                onChange={(e) => SetPresence(e.target.value)} 
                            ></textarea>
                        </div>
                        
                        <div className='input-box'>
                            <label htmlFor="email">E-mail du client</label>
                            <input 
                                type="email" 
                                id="email" 
                                name="email" 
                                placeholder="Entrez l'e-mail"
                                value={email} 
                                onChange={(e) => setEmail(e.target.value)} 
                            />
                        </div>

                        <div className='input-box'>
                            <label htmlFor="amount">Frais mensuels</label>
                            <input 
                                type="text"
                                id="amount" 
                                name="amount" 
                                placeholder="Entrez le montant mensuel"
                                value={amount} 
                                onChange={(e) => setAmount(e.target.value)} 
                            />
                        </div>
                        <div className='container-date'>
                            <div className='input-box'>
                                <label htmlFor="start-date">Date d'ouverture</label>
                                <input 
                                    type="text" 
                                    id="start-date" 
                                    name="start-date" 
                                    placeholder="DD.MM.YYYY"
                                    value={startDate} 
                                    onChange={(e) => setStartDate(e.target.value)} 
                                />
                            </div>
                            <div className='input-box'>
                                <label htmlFor="end-date">Date de finalisation</label>
                                <input 
                                    type="text" 
                                    id="end-date" 
                                    name="end-date" 
                                    placeholder="DD.MM.YYYY"
                                    value={endDate} 
                                    onChange={(e) => setEndDate(e.target.value)} 
                                />
                            </div>
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div className='background-preview-create'>
            {showWarning ? (
                <div className="no-screen">
                    <p>Votre appareil ou le format de l'écran n'est pas compatible avec cette application. Veuillez utiliser un appareil avec une résolution supérieure à 950px de largeur.</p>
                </div>
            ) : (
                <div className='wrapper-preview-create'>
                    {alert.show && (
                        <Alert 
                            message={alert.message} 
                            type={alert.type} 
                            onClose={() => setAlert({ message: '', type: '', show: false })} 
                        />
                    )}
                    <h1>Création du dossier</h1>

                    <div className='input-box'>
                        <label htmlFor="project-type">Type de projet</label>
                        <select 
                            id="project-type" 
                            name="project-type" 
                            value={projectType} 
                            onChange={(e) => setProjectType(e.target.value)}
                        >
                            <option value="">Sélectionner un type de projet</option>
                            <option value="MyWebsite">MyWebsite</option>
                            <option value="DigitalCampaigns">DigitalCampaigns</option>
                        </select>
                    </div>

                    {renderFormForProjectType()}

                    <div className='container-btn'>
                        <button onClick={() => navigate("/gestion")}>Annuler</button>
                        {projectType === 'MyWebsite' && (
                            <button onClick={handleCreateWebsite}>Créer le dossier</button>
                        )}
                        {projectType === 'DigitalCampaigns' && (
                            <button onClick={handleCreateDigitalCampaign}>Créer le dossier</button>
                        )}
                    </div>

                </div>
            )}
        </div>
    );
};

export default PreviewCreate;
