import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import './PreviewClient.css';
import Alert from '../Alert/Alert';

const formatDate = (dateStr) => {
    if (!dateStr) return 'Date non disponible';
    
    const date = new Date(dateStr);
    const moisAnnee = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
    
    const jourMois = date.getUTCDate();
    const mois = moisAnnee[date.getUTCMonth()];
    const annee = date.getUTCFullYear();
    
    return `${jourMois} ${mois} ${annee}`;
};

function PreviewClient() {
    const location = useLocation();
    const navigate = useNavigate();
    const [project, setProject] = useState(null);
    const [projectType, setProjectType] = useState(''); 
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [alert, setAlert] = useState({ message: '', type: '', show: false });
    const iban = "CH76 00767 000C 5538 5540";

    const fetchProjectData = (folderId) => {
        fetch(`/Project`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                action: 'check_folder_id_type',
                folder_id: folderId
            }),
        })
        .then(response => response.json())
        .then(data => {
            if (data.success && data.project) {
                setProject(data.project);
                setProjectType(data.type); 
            } else {
                console.error("Erreur lors de la récupération des données:", data.message);
                setAlert({ message: 'Erreur lors de la récupération des données du projet', type: 'error', show: true });
            }
        })
        .catch(error => {
            console.error('Erreur lors de la récupération des données:', error);
            setAlert({ message: 'Erreur lors de la récupération des données du projet', type: 'error', show: true });
        })
        .finally(() => setLoading(false));
    };       

    useEffect(() => {
        const folderId = location.state?.folder_id; 
        if (folderId) {
            fetchProjectData(folderId);
        } else {
            setLoading(false);
            setError('ID de projet non fourni');
        }
    }, [location.state?.folder_id]);

    const openLink = (url) => {
        if (url) {
            window.open(url, '_blank', 'noopener,noreferrer');
        }
    };
    
    const ReturnHome = () => {
        navigate("/accueil");
    };

    if (loading) {
        return <div>Chargement...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    const formattedAmount = project && project.amount !== undefined ? `${project.amount} CHF` : 'Montant non disponible';

    const calculatePercentage = (amount, percentage) => {
        if (!amount || isNaN(amount)) return 'Montant non disponible';
        return `${(amount * (percentage / 100)).toFixed(2)} CHF`;
    };

    const startDateFormatted = project?.start ? formatDate(project.start) : 'Date non disponible';
    const endDateFormatted = project?.end ? formatDate(project.end) : 'Date non disponible';

    const openInNewTab = (url) => {
        if (url) {
            if (!/^https?:\/\//i.test(url)) {
                url = 'http://' + url;
            }
            window.open(url, '_blank', 'noopener,noreferrer');
        }
    };

    const handleCopyIban = () => {
        navigator.clipboard.writeText(iban).then(() => {
            setAlert({ message: "IBAN copié avec succès.", type: 'success', show: true });
        }).catch(err => {
            console.error('Erreur lors de la copie de l\'IBAN : ', err);
        });
    };

    const renderProjectForm = () => {
        switch (projectType) {
            case 'Website':
                return (
                    <>
                        <div className='input-box'>
                            <label htmlFor="site-theme">Que sera le thème du site ?</label>
                            <textarea id="site-theme" name="site-theme" value={project?.site_theme || ''} placeholder='Vide' readOnly></textarea>
                        </div>
                        <div className='input-box'>
                            <label htmlFor="site-colors">Les couleurs principales à utiliser ?</label>
                            <textarea id="site-colors" name="site-colors" value={project?.site_colors || ''} placeholder='Vide' readOnly></textarea>
                        </div>
                        <div className='input-box'>
                            <label htmlFor="target-audience">Qui est votre public cible ?</label>
                            <textarea id="target-audience" name="target-audience" value={project?.target_audience || ''} placeholder='Vide' readOnly></textarea>
                        </div>
                        <div className='input-box'>
                            <label htmlFor="objectives">Quels sont vos principaux objectifs en ligne ?</label>
                            <textarea id="objectives" name="objectives" value={project?.objectives || ''} placeholder='Vide' readOnly></textarea>
                        </div>
                        <div className='input-box'>
                            <label htmlFor="keywords">Quels sont les 3 à 5 mots-clés qui représentent votre entreprise ?</label>
                            <textarea id="keywords" name="keywords" value={project?.keywords || ''} placeholder='Vide' readOnly></textarea>
                        </div>
                        <div className='input-box'>
                            <label htmlFor="competitors">Connaissez-vous vos principaux concurrents en ligne ?</label>
                            <textarea id="competitors" name="competitors" value={project?.competitors || ''} placeholder='Vide' readOnly></textarea>
                        </div>
                        <div className='input-box'>
                            <label htmlFor="additional-notes">Notes supplémentaires</label>
                            <textarea id="additional-notes" name="additional-notes" value={project?.additional_notes || ''} placeholder='Vide' readOnly></textarea>
                        </div>
                        <div className='input-box'>
                            <label htmlFor="email">E-mail de correspondance</label>
                            <input type="email" id="email" name="email" value={project?.email || ''} placeholder='Vide' readOnly />
                        </div>   
                        <h2>Facturation</h2>
                        <div className='container-amount'>
                            <div className='input-box'>
                                <label htmlFor="payment-20">Paiement 40%</label>
                                <input
                                    type="text"
                                    id="payment-20"
                                    name="payment-20"
                                    value={project ? calculatePercentage(project.amount, 40) : 'Montant non disponible'}
                                    readOnly
                                />
                            </div>
                            <div className='input-box'>
                                <label htmlFor="payment-50">Paiement 40%</label>
                                <input
                                    type="text"
                                    id="payment-50"
                                    name="payment-50"
                                    value={project ? calculatePercentage(project.amount, 40) : 'Montant non disponible'}
                                    readOnly
                                />
                            </div>
                            <div className='input-box'>
                                <label htmlFor="payment-30">Paiement 20%</label>
                                <input
                                    type="text"
                                    id="payment-30"
                                    name="payment-30"
                                    value={project ? calculatePercentage(project.amount, 20) : 'Montant non disponible'}
                                    readOnly
                                />
                            </div>
                            <div className='input-box'>
                                <label htmlFor="total-amount">Somme à régler</label>
                                <input
                                    type="text"
                                    id="total-amount"
                                    name="total-amount"
                                    value={formattedAmount}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className='container-iban'>
                            <h4>Coordonnées de l'entreprise</h4>
                            <p>Société: <span>Logixcube – Cornu</span></p>
                            <p>Adresse: <span>Route de Cossonay 15, 1008 Prilly</span></p>
                            <p>Téléphone: <span>+41 76 506 24 24</span></p>
                            <p>E-mail: <span>compta@logixcube.ch</span></p>
                            <p>IBAN: <span>{iban}<FontAwesomeIcon className='copy-iban' onClick={handleCopyIban} icon={faCopy} /></span></p>
                        </div>
                        <div className='container-date'>
                            <div className='input-box'>
                                <label htmlFor="start-date">Date d'ouverture</label>
                                <input type="text" id="start-date" name="start-date" value={formatDate(project?.start)} readOnly />
                            </div>
                            <div className='input-box'>
                                <label htmlFor="end-date">Date de finalisation</label>
                                <input type="text" id="end-date" name="end-date" value={formatDate(project?.end)} readOnly />
                            </div>
                        </div>
                        <div className='input-box'>
                            <label htmlFor="site-preview">Aperçu du site</label>
                            <input type="text" id="site-preview" name="site-preview" value={project?.preview_url || ''} readOnly onClick={() => openInNewTab(project?.preview_url)} style={{ cursor: 'pointer' }} />
                        </div>   
                        <div className='container-progress'>
                            <p className='progress-text'>Progression du projet</p>
                            <div className='progress-wrapper'>
                                <div className='bar-progress' style={{ width: `${project?.progress || 0}%` }}></div>
                            </div>
                            <p className='progress-percentage'>{project?.progress || 0}%</p>
                        </div>
                    </>
                );
            case 'DigitalCampaigns':
                return (
                    <>
                        {project?.facebook === 1 && (
                            <div className='input-box'>
                                <label htmlFor="facebook-link">Lien vers votre profil Facebook</label>
                                <input
                                    type="text"
                                    id="facebook-link"
                                    name="facebook-link"
                                    value={project?.social_facebook || ''}
                                    readOnly
                                    onClick={() => openLink(project?.social_facebook)} 
                                    style={{ cursor: 'pointer' }} 
                                />
                            </div>
                        )}

                        {project?.instagram === 1 && (
                            <div className='input-box'>
                                <label htmlFor="instagram-link">Lien vers votre profil Instagram</label>
                                <input
                                    type="text"
                                    id="instagram-link"
                                    name="instagram-link"
                                    value={project?.social_instagram || ''}
                                    readOnly
                                    onClick={() => openLink(project?.social_instagram)} 
                                    style={{ cursor: 'pointer' }} 
                                />
                            </div>
                        )}

                        {project?.youtube === 1 && (
                            <div className='input-box'>
                                <label htmlFor="youtube-link">Lien vers votre profil YouTube</label>
                                <input
                                    type="text"
                                    id="youtube-link"
                                    name="youtube-link"
                                    value={project?.social_youtube || ''}
                                    readOnly
                                    onClick={() => openLink(project?.social_youtube)} 
                                    style={{ cursor: 'pointer' }} 
                                />
                            </div>
                        )}

                        {project?.linkedin === 1 && (
                            <div className='input-box'>
                                <label htmlFor="linkedin-link">Lien vers votre profil LinkedIn</label>
                                <input
                                    type="text"
                                    id="linkedin-link"
                                    name="linkedin-link"
                                    value={project?.social_linkedin || ''}
                                    readOnly
                                    onClick={() => openLink(project?.social_linkedin)} 
                                    style={{ cursor: 'pointer' }} 
                                />
                            </div>
                        )}

                        {project?.tiktok === 1 && (
                            <div className='input-box'>
                                <label htmlFor="tiktok-link">Lien vers votre profil Tiktok</label>
                                <input
                                    type="text"
                                    id="tiktok-link"
                                    name="tiktok-link"
                                    value={project?.social_tiktok || ''}
                                    readOnly
                                    onClick={() => openLink(project?.social_tiktok)} 
                                    style={{ cursor: 'pointer' }} 
                                />
                            </div>
                        )}

                        {project?.snapchat === 1 && (
                            <div className='input-box'>
                                <label htmlFor="snapchat-link">Lien vers votre profil Snapchat</label>
                                <input
                                    type="text"
                                    id="snapchat-link"
                                    name="snapchat-link"
                                    value={project?.social_snapchat || ''}
                                    readOnly
                                    onClick={() => openLink(project?.social_snapchat)} 
                                    style={{ cursor: 'pointer' }} 
                                />
                            </div>
                        )}

                        <div className='input-box'>
                            <label htmlFor="objectives">Quels sont vos principaux objectifs sur les réseaux sociaux ?</label>
                            <textarea
                                id="objectives"
                                name="objectives"
                                value={project?.objectives || ''}
                                placeholder="Vide"
                                readOnly
                            ></textarea>
                        </div>

                        <div className='input-box'>
                            <label htmlFor="presence">Voulez-vous accroître votre présence sur les réseaux sociaux ?</label>
                            <textarea
                                id="presence"
                                name="presence"
                                value={project?.presence || ''}
                                placeholder="Vide"
                                readOnly
                            ></textarea>
                        </div>
                        <div className='input-box'>
                            <label htmlFor="email">E-mail de correspondance</label>
                            <input type="email" id="email" name="email" value={project?.email || ''} placeholder='Vide' readOnly />
                        </div>  
                        <h2>Facturation</h2>
                        <div className='input-box'>
                            <label htmlFor="monthly-amount">Somme due chaque mois</label>
                            <input
                                type="text"
                                id="monthly-amount"
                                name="monthly-amount"
                                value={formattedAmount}
                                readOnly
                            />
                        </div>
                        <div className='container-iban'>
                            <h4>Coordonnées de l'entreprise</h4>
                            <p>Société: <span>Logixcube – Cornu</span></p>
                            <p>Adresse: <span>Route de Cossonay 15, 1008 Prilly</span></p>
                            <p>Téléphone: <span>+41 76 506 24 24</span></p>
                            <p>E-mail: <span>compta@logixcube.ch</span></p>
                            <p>IBAN: <span>{iban}<FontAwesomeIcon className='copy-iban' onClick={handleCopyIban} icon={faCopy} /></span></p>
                        </div>
                        <div className='container-date'>
                            <div className='input-box'>
                                <label htmlFor="start-date">Date d'ouverture</label>
                                <input type="text" id="start-date" name="start-date" value={startDateFormatted} readOnly />
                            </div>
                            <div className='input-box'>
                                <label htmlFor="end-date">Date de finalisation</label>
                                <input type="text" id="end-date" name="end-date" value={endDateFormatted} readOnly />
                            </div>
                        </div>
                        
                    </>
                );
            default:
                return <p>Type de projet non reconnu</p>;
        }
    };    

    return (
        <div className='background-preview'>
            <div className='wrapper-preview'>
                <div className='container-back'>
                    <button onClick={ReturnHome}><FontAwesomeIcon icon={faArrowLeft} /></button>
                </div>
                
                {alert.show && (
                    <Alert 
                        message={alert.message} 
                        type={alert.type} 
                        onClose={() => setAlert({ message: '', type: '', show: false })} 
                    />
                )}
                <h1>Aperçu du dossier</h1>
                <div className='input-box'>
                    <label htmlFor="project-name">Nom du projet</label>
                    <input type="text" id="project-name" name="project-name" value={project?.name || ''} readOnly />
                </div>
                <div className='input-box'>
                    <label htmlFor="folder-id">Numéro de dossier</label>
                    <input type="text" id="folder-id" name="folder-id" value={project?.folder_id || ''} readOnly />
                </div>
                {renderProjectForm()}
            </div>
        </div>
    );
}

export default PreviewClient;
