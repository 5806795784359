import React, { createContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [project, setProject] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('token');
        const storedProject = localStorage.getItem('activeProject');
        const storedUser = localStorage.getItem('user');
        const storedTimestamp = localStorage.getItem('timestamp');
        const currentTime = new Date().getTime();
        const sessionDuration = 2 * 60 * 60 * 1000; 
    
        if (storedTimestamp && currentTime - storedTimestamp > sessionDuration) {
            logout(); 
        }
    
        if (storedUser) {
            setUser(JSON.parse(storedUser)); 
        }
    
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                if (decodedToken.exp * 1000 < currentTime) {
                    logout(); 
                } else {
                    const { userId, userType, folder_id, name, role, grade } = decodedToken.data;
    
                    if (userType === 'user') {
                        if (!storedUser) {
                            setUser({ id: userId, name, role, grade });
                            localStorage.setItem('user', JSON.stringify({ id: userId, name, role, grade }));
                        }
                    } else if (storedProject) {
                        const projectData = JSON.parse(storedProject);
                        projectData.folder_id = folder_id; 
                        setProject(projectData);
                    }
                }
            } catch (error) {
                console.error('Erreur de décodage du JWT:', error);
                logout();
            }
        }
    
        setLoading(false);
    }, []);
    
    const login = (token, project) => {
        localStorage.setItem('token', token);
    
        const decodedToken = jwtDecode(token);
        const timestamp = new Date().getTime();
        localStorage.setItem('timestamp', timestamp);
    
        const { userId, userType, folder_id, name, code, role, grade } = decodedToken.data;
    
        if (userType === 'user') {
            setUser({ id: userId, name, role, grade });
            localStorage.setItem('user', JSON.stringify({ id: userId, name, role, grade }));
        } else {
            const projectData = {
                id: folder_id,  
                type: userType,
                name,
                folder_id: folder_id, 
                code
            };
    
            setProject(projectData);
            localStorage.setItem('project', JSON.stringify(projectData));
            localStorage.setItem('activeProject', JSON.stringify(projectData));
        }
    };      

    const logout = () => {
        setUser(null);
        setProject(null);
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('project');
        localStorage.removeItem('timestamp');
        localStorage.removeItem('activeProject');
    };

    return (
        <AuthContext.Provider value={{ user, project, setProject, loading, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
