import React, { useState, useEffect, useCallback } from 'react'; 
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faXmark } from '@fortawesome/free-solid-svg-icons';
import './PreviewManagement.css';
import Alert from '../Alert/Alert';
 
const formatDate = (dateStr) => {
    if (!dateStr) return '';

    const date = new Date(dateStr);
    const moisAnnee = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

    const jourMois = date.getUTCDate();
    const mois = moisAnnee[date.getUTCMonth()];
    const annee = date.getUTCFullYear();

    return `${jourMois} ${mois} ${annee}`;
};

function PreviewManagement() {
    const location = useLocation();
    const navigate = useNavigate();
    const initialProject = location.state?.project || {};
    const [project, setProject] = useState(initialProject);
    const [projectType, setProjectType] = useState('');
    const [projectName, setProjectName] = useState('');
    const [folderId, setFolderId] = useState(location.state?.folder_id || project.folder_id || '');
    const [theme, setTheme] = useState(project.site_theme || '');
    const [colors, setColors] = useState(project.site_colors || '');
    const [audience, setAudience] = useState(project.target_audience || '');
    const [objectives, setObjectives] = useState('');
    const [keywords, setKeywords] = useState('');
    const [competitors, setCompetitors] = useState('');
    const [notes, setNotes] = useState(project.additional_notes || '');
    const [amount, setAmount] = useState(project.amount || '');
    const [email, setEmail] = useState(project.email || '');
    const [previewUrl, setPreviewUrl] = useState(project.preview_url || '');
    const [progress, setProgress] = useState(project.progress || '');
    const [alert, setAlert] = useState({ message: '', type: '', show: false });
    const [showWarning, setShowWarning] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState('');
    const [assignedUsers, setAssignedUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [socialMedia, setSocialMedia] = useState({
        facebook: false,
        facebookLink: '',
        instagram: false,
        instagramLink: '',
        youtube: false,
        youtubeLink: '',
        linkedin: false,
        linkedinLink: '',
        tiktok: false,
        tiktokLink: '',
        snapchat: false,
        snapchatLink: '',
    });
    const [presence, setPresence] = useState(''); 

    const ReturnHome = () => {
        navigate("/gestion");
    };

    const fetchProjectData = useCallback(async (folderId) => {
        try {
            const response = await fetch(`/Project`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'check_folder_id_type',
                    folder_id: folderId,
                }),
            });

            const data = await response.json();

            if (data.success && data.project) {
                const projectData = data.project;
                setProject(projectData);
                setProjectType(data.type || '');
                setProjectName(projectData.name || '');
                setFolderId(projectData.folder_id || '');

                const assignedUsersData = await fetchAssignedUsers(projectData.assigned);
                setAssignedUsers(assignedUsersData);

                setAmount(projectData.amount || '');
                setEmail(projectData.email || '');
                setPreviewUrl(projectData.preview_url || '');
                setProgress(projectData.progress || '');
                setTheme(projectData.site_theme || '');
                setColors(projectData.site_colors || '');
                setAudience(projectData.target_audience || '');
                setObjectives(projectData.objectives || '');
                setKeywords(projectData.keywords || '');
                setCompetitors(projectData.competitors || '');
                setNotes(projectData.additional_notes || '');

                if (data.type === 'DigitalCampaigns') {
                    setSocialMedia({
                        facebook: projectData.facebook === 1,
                        facebookLink: projectData.social_facebook || '',
                        instagram: projectData.instagram === 1,
                        instagramLink: projectData.social_instagram || '',
                        youtube: projectData.youtube === 1,
                        youtubeLink: projectData.social_youtube || '',
                        linkedin: projectData.linkedin === 1,
                        linkedinLink: projectData.social_linkedin || '',
                        snapchat: projectData.snapchat === 1,
                        snapchatLink: projectData.social_snapchat || '',
                    });
                    setPresence(projectData.presence || '');
                }
            } else {
                console.error("Erreur lors de la récupération des données:", data.message);
                setAlert({ message: 'Erreur lors de la récupération des données du projet', type: 'error', show: true });
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des données:', error);
            setAlert({ message: 'Erreur lors de la récupération des données du projet', type: 'error', show: true });
        }
    }, []);

    useEffect(() => {
        if (folderId) {
            fetchProjectData(folderId);
        }
    }, [folderId, fetchProjectData]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetch('/Users?action=get_users');
                const data = await response.json();
                if (data.success) {
                    setUsers(data.users);
                } else {
                    console.error('Error fetching users:', data.message);
                }
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, []);

    const fetchAssignedUsers = async (assignedIds) => {
        try {
            const idsArray = assignedIds ? JSON.parse(assignedIds) : [];
    
            if (idsArray.length === 0) return [];
    
            const response = await fetch('/Users?action=get_assigned_users', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userIds: idsArray }),
            });
    
            const data = await response.json();
    
            if (data.success) {
                return data.users;
            } else {
                console.error('Erreur lors de la récupération des utilisateurs assignés :', data.message);
                return [];
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des utilisateurs assignés :', error);
            return [];
        }
    };

    const handleAddUser = (userId) => {
        const user = users.find(u => u.id === parseInt(userId));
        if (user && !assignedUsers.some(u => u.id === user.id)) { 
            setAssignedUsers([...assignedUsers, user]); 
        }
        setSelectedUserId(''); 
    };    

    const handleRemoveUser = (userId) => {
        setAssignedUsers(assignedUsers.filter(user => user.id !== userId)); 
    };

    const renderAssignedUsers = () => {
        return assignedUsers.map(user => (
            <div key={user.id} className="assigned-box">
                <p>{user.forname} {user.lastname}</p>
                <FontAwesomeIcon 
                    className="delete-assigned-box" 
                    icon={faXmark} 
                    onClick={() => handleRemoveUser(user.id)} 
                />
            </div>
        ));
    };    

    useEffect(() => {
        if (projectType === 'DigitalCampaigns' && project) {
            setSocialMedia({
                facebook: project.facebook === 1, 
                facebookLink: project.social_facebook || '',
                instagram: project.instagram === 1, 
                instagramLink: project.social_instagram || '',
                youtube: project.youtube === 1,
                youtubeLink: project.social_youtube || '',
                linkedin: project.linkedin === 1,
                linkedinLink: project.social_linkedin || '',
                snapchat: project.snapchat === 1,
                snapchatLink: project.social_snapchat || '',
            });
        }
    }, [project, projectType]);    
    
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 950) {
                setShowWarning(true);
            } else {
                setShowWarning(false);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSave = () => {
        const updatedProject = {
            id: project.id,
            folder_id: folderId,
            site_theme: theme,
            site_colors: colors,
            target_audience: audience,
            keywords: keywords,
            competitors: competitors,
            objectives: objectives,
            additional_notes: notes,
            amount: amount,
            email: email,
            preview_url: previewUrl,
            progress: progress,
            facebook: socialMedia.facebook ? 1 : 0,
            social_facebook: socialMedia.facebookLink,
            instagram: socialMedia.instagram ? 1 : 0,
            social_instagram: socialMedia.instagramLink,
            youtube: socialMedia.youtube ? 1 : 0,
            social_youtube: socialMedia.youtubeLink,
            linkedin: socialMedia.linkedin ? 1 : 0,  
            social_linkedin: socialMedia.linkedinLink,
            tiktok: socialMedia.tiktok ? 1 : 0,
            social_tiktok: socialMedia.tiktokLink,
            snapchat: socialMedia.snapchat ? 1 : 0,
            social_snapchat: socialMedia.snapchatLink,
            presence: presence,  
            assigned_users: assignedUsers.map(user => user.id), 
            action: 'update_project',
            type: projectType
        };
    
        fetch(`/Project`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedProject),
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                setAlert({ message: 'Projet mis à jour avec succès', type: 'success', show: true });
                fetchProjectData(folderId);  
            } else {
                setAlert({ message: 'Erreur lors de la mise à jour du projet', type: 'error', show: true });
            }
        })
        .catch(error => {
            setAlert({ message: 'Erreur lors de la mise à jour du projet', type: 'error', show: true });
        });
    };
    
    
    const handleProgressChange = (e) => {
        const newValue = e.target.value;
        setProgress(newValue);

        e.target.style.setProperty('--progress-value', `${newValue}%`);
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setSocialMedia({
            ...socialMedia,
            [name]: checked, 
        });
    };    

    const startDateFormatted = formatDate(project.start);
    const endDateFormatted = formatDate(project.end);

    const renderProjectForm = () => {
        switch (projectType) {
            case 'Website':
                return (
                    <>
                        <div className='input-box'>
                            <label htmlFor="site-theme">Que sera le thème du site ?</label>
                            <textarea id="site-theme" name="site-theme" value={theme} onChange={(e) => setTheme(e.target.value)} placeholder='Vide'></textarea>
                        </div>
                        <div className='input-box'>
                            <label htmlFor="site-colors">Les couleurs principales à utiliser ?</label>
                            <textarea id="site-colors" name="site-colors" value={colors} onChange={(e) => setColors(e.target.value)} placeholder='Vide'></textarea>
                        </div>
                        <div className='input-box'>
                            <label htmlFor="target-audience">Qui est votre public cible ?</label>
                            <textarea id="target-audience" name="target-audience" value={audience} onChange={(e) => setAudience(e.target.value)} placeholder='Vide'></textarea>
                        </div>
                        <div className='input-box'>
                            <label htmlFor="objectives">Quels sont vos principaux objectifs en ligne ?</label>
                            <textarea id="objectives" name="objectives" value={objectives} onChange={(e) => setObjectives(e.target.value)} placeholder='Vide'></textarea>
                        </div>
                        <div className='input-box'>
                            <label htmlFor="keywords">Quels sont les 3 à 5 mots-clés qui représentent votre entreprise ?</label>
                            <textarea id="keywords" name="keywords" value={keywords} onChange={(e) => setKeywords(e.target.value)} placeholder='Vide'></textarea>
                        </div>
                        <div className='input-box'>
                            <label htmlFor="competitors">Connaissez-vous vos principaux concurrents en ligne ?</label>
                            <textarea id="competitors" name="competitors" value={competitors} onChange={(e) => setCompetitors(e.target.value)} placeholder='Vide'></textarea>
                        </div>
                        <div className='input-box'>
                            <label htmlFor="additional-notes">Notes supplémentaires</label>
                            <textarea id="additional-notes" name="additional-notes" value={notes} onChange={(e) => setNotes(e.target.value)} placeholder='Vide'></textarea>
                        </div>
                        <div className='input-box'>
                            <label htmlFor="total-amount">Montant total</label>
                            <input type="text" id="total-amount" name="total-amount" value={amount} onChange={(e) => setAmount(e.target.value)} readOnly />
                        </div>
                        <div className='input-box'>
                            <label htmlFor="email">E-mail du client</label>
                            <input type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Vide' />
                        </div>
                        <div className='input-box'>
                            <label htmlFor="site-preview">Aperçu du site</label>
                            <input type="text" id="site-preview" name="site-preview" value={previewUrl} onChange={(e) => setPreviewUrl(e.target.value)} placeholder='Vide' />
                        </div>
                        <div className='container-date'>
                            <div className='input-box'>
                                <label htmlFor="start-date">Date d'ouverture</label>
                                <input type="text" id="start-date" name="start-date" value={startDateFormatted} readOnly />
                            </div>
                            <div className='input-box'>
                                <label htmlFor="end-date">Date de finalisation</label>
                                <input type="text" id="end-date" name="end-date" value={endDateFormatted} readOnly />
                            </div>
                        </div>
                        <div className='container-progress'>
                            <p className='progress-text'>Progression du projet</p>
                            <input
                                type="range"
                                min="0"
                                max="100"
                                value={progress}
                                onChange={handleProgressChange}
                                className="progress-slider"
                                style={{ '--progress-value': `${progress}%` }}
                            />
                            <p className='progress-percentage'>{progress}%</p>
                        </div>
                    </>
                );
            case 'DigitalCampaigns':
                return (
                    <>
                        <div className='input-box'>
                            <label>Sur quels réseaux sociaux êtes-vous actuellement actif ?</label>
                            <div className='checkbox-group'>
                                {['facebook', 'instagram', 'youtube', 'linkedin', 'snapchat'].map((social) => (
                                    <div key={social} className='social-group'>
                                        <div className='container-social'>
                                            <input
                                                type="checkbox"
                                                id={social}
                                                name={social}
                                                checked={socialMedia[social]}  
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor={social}>
                                                {social.charAt(0).toUpperCase() + social.slice(1)}
                                            </label>
                                        </div>
                                        {socialMedia[social] && (
                                            <div className="social-input">
                                                <input
                                                    type="url"
                                                    id={`${social}-link`}
                                                    name={`${social}-link`}
                                                    placeholder={`Entrez le lien de votre profil ${social}`}
                                                    value={socialMedia[`${social}Link`]}  
                                                    onChange={(e) =>
                                                        setSocialMedia({
                                                            ...socialMedia,
                                                            [`${social}Link`]: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className='input-box'>
                            <label htmlFor="objectives">Quels sont vos principaux objectifs sur les réseaux sociaux ?</label>
                            <textarea
                                id="objectives"
                                name="objectives"
                                value={objectives}  
                                onChange={(e) => setObjectives(e.target.value)} 
                                placeholder="Décrivez votre présence actuelle ou vos objectifs"
                            ></textarea>
                        </div>

                        <div className='input-box'>
                            <label htmlFor="presence">Voulez-vous accroître votre présence sur les réseaux sociaux ?</label>
                            <textarea
                                id="presence"
                                name="presence"
                                value={presence}  
                                onChange={(e) => setPresence(e.target.value)} 
                                placeholder="Décrivez votre présence actuelle ou vos objectifs"
                            ></textarea>
                        </div>

                        <div className='input-box'>
                            <label htmlFor="email">E-mail du client</label>
                            <input type="email" id="email" name="email" placeholder="Entrez l'e-mail" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>

                        <div className='input-box'>
                            <label htmlFor="amount">Frais mensuels</label>
                            <input type="text" id="amount" name="amount" placeholder="Entrez le montant mensuel" value={amount} onChange={(e) => setAmount(e.target.value)} readOnly />
                        </div>

                        <div className='container-date'>
                            <div className='input-box'>
                                <label htmlFor="start-date">Date d'ouverture</label>
                                <input type="text" id="start-date" name="start-date" value={startDateFormatted} readOnly />
                            </div>
                            <div className='input-box'>
                                <label htmlFor="end-date">Date de finalisation</label>
                                <input type="text" id="end-date" name="end-date" value={endDateFormatted} readOnly />
                            </div>
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div className='background-preview-management'>
            {showWarning ? (
                <div className="no-screen">
                    <p>Votre appareil ou le format de l'écran n'est pas compatible avec cette application. Veuillez utiliser un appareil avec une résolution supérieure à 950px de largeur.</p>
                </div>
            ) : (
                <div className='wrapper-preview-management'>
                    {alert.show && (
                        <Alert
                            message={alert.message}
                            type={alert.type}
                            onClose={() => setAlert({ message: '', type: '', show: false })}
                        />
                    )}
                    <div className='container-back'>
                        <button onClick={ReturnHome}><FontAwesomeIcon icon={faArrowLeft} /></button>
                    </div>
                    <h1>Aperçu du dossier</h1>

                    <div className='input-box'>
                        <label htmlFor="project-type">Type de projet</label>
                        <input type="text" id="project-type" name="project-type" value={projectType} readOnly />
                    </div>
                    <div className='input-box'>
                        <label htmlFor="folder-id">Nom du dossier</label>
                        <input type="text" id="name" name="name" value={projectName} readOnly />
                    </div>
                    <div className='input-box'>
                        <label htmlFor="folder-id">Numéro de dossier</label>
                        <input type="text" id="folder-id" name="folder-id" value={folderId} readOnly />
                    </div>
                    <div className='input-box'>
                        <label htmlFor="assigned-user">Assigner à un utilisateur</label>
                        <select 
                            id="assigned-user" 
                            name="assigned-user" 
                            value={selectedUserId} 
                            onChange={(e) => handleAddUser(e.target.value)} 
                        >
                            <option value="">Sélectionner un utilisateur</option>
                            {users.map((user) => (
                                <option key={user.id} value={user.id}>
                                    {user.forname} {user.lastname}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='container-assigned'>
                        {renderAssignedUsers()}
                    </div>

                    {renderProjectForm()}
                    <div className='container-btn'>
                        <button className='save-btn' onClick={handleSave}>Sauvegarder</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PreviewManagement;
